import { useLocation, useNavigate } from 'react-router-dom';
import scrollToPage from '../../utils/ScrollToLink';
import kortaLogo from '../../assets/imgs/korta_logo_white.svg';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div id='footer'>
      <div className='footer_contact'>
        <div className='footer_logo' onClick={() => scrollToPage('home', location, navigate)}>
          <img src={kortaLogo} alt='korta logo footer' />
        </div>
        <p>
          <a
            href='https://www.google.com/maps/place/Plinarska+ul.+31,+21000,+Split/@43.5112214,16.4324491,17z/data=!3m1!4b1!4m6!3m5!1s0x13355dfc0753a299:0xb34bc5229db00462!8m2!3d43.5112175!4d16.4346378!16s%2Fg%2F11c1ys7bzk?hl=en'
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            Plinarska ulica 31
          </a>
        </p>
        <p>21000 Split, Croatia</p>
        <p>
          <a href='tel:+385 21 571 226' className='opacity_transition_low'>
            +385 21 571 226
          </a>
        </p>
        <p>
          <a href='tel:+385 98 571 226' className='opacity_transition_low'>
            +385 98 571 226
          </a>
        </p>
        <p>
          <a
            href='mailto:reservations@kortasplit.com'
            className='opacity_transition_low'
          >
            reservations@kortasplit.com
          </a>
        </p>
      </div>
      <div className='footer_links'>
        <div
          className='opacity_transition_high'
          onClick={() => scrollToPage('impressum', location, navigate)}
        >
          IMPRESSUM
        </div>
        <div
          className='opacity_transition_high'
          onClick={() => scrollToPage('rules', location, navigate)}
        >
          HOTEL RULES
        </div>
      </div>
      <div className='footer_bottom'>
        <p>Copyright © Korta {new Date().getFullYear()}.</p>
      </div>
    </div>
  );
};

export default Footer;
