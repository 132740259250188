import { useEffect } from 'react';
import scrollDirection from './ScrollDirection';

const FadeInSection = () => {
  const direction = scrollDirection();
  let count: number = 0.1;

  const observerOptions = {
    root: null,
    threshold: 0,
    rootMargin: '0px 0px 0px 0px',
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && direction !== 'up') {
        entry.target.classList.add('is_visible');
        entry.target.setAttribute(
          'style',
          'transition-delay: ' + count++ * 0.3 + 's'
        );
      }
    });
    count = 0;
  }, observerOptions);

  return useEffect(() => {
    const sections: NodeListOf<Element> = document.querySelectorAll('.fade_in');

    if (sections) {
      sections.forEach((section) => {
        observer.observe(section);
      });
    }
  });
};

export default FadeInSection;
