import Header from '../components/00_header/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../components/01_footer/Footer';

const Layout = () => {
  return (
    <div className='body_wrapper'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
