import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import duplex from '../../../../assets/imgs/home/apartments/duplex.jpg';
import studioApartments from '../../../../assets/imgs/home/apartments/apartments.jpg';
import room from '../../../../assets/imgs/home/apartments/room.jpg';
import scrollToPage from '../../../../utils/ScrollToLink';
import Constants from '../../../../utils/Constants';

interface Accommodation {
  accommodationtName: string;
  accommodationtLink: string;
  accommodationtImage: string;
}

const RoomAndApartments = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const accommodations: Array<Accommodation> = [
    {
      accommodationtName: 'Duplex',
      accommodationtLink: 'duplex',
      accommodationtImage: duplex,
    },
    {
      accommodationtName: 'Studio Apartments',
      accommodationtLink: 'apartments',
      accommodationtImage: studioApartments,
    },
    {
      accommodationtName: 'Room',
      accommodationtLink: 'room',
      accommodationtImage: room,
    },
  ];

  const slickSettings = {
    draggable: false,
    swipe: false,
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i: number) => (
      <div>{accommodations[i].accommodationtName}</div>
    ),
  };

  return (
    <div id='accommodations'>
      <div className='accommodations container'>
        <h2 className='text_center'>Room & Apartments</h2>
        <Slider {...slickSettings} className='accommodations_slick'>
          {accommodations.map((x: Accommodation) => (
            <div className='accommodations_slick_container'>
              <img
                src={x.accommodationtImage}
                alt={x.accommodationtName}
                className='accommodations_slick_container_image'
              />
              <div
                data-index={`${accommodations.indexOf(x)}`}
                className='accommodations_slick_container_box fade_in'
              >
                <div className='accommodations_slick_container_box_title'>
                  {x.accommodationtName}
                </div>
                <div className='accommodations_slick_container_box_links'>
                  <a
                    href={Constants.bookNow}
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low'
                  >
                    <div>BOOK NOW</div>
                  </a>
                  <div
                    className='opacity_transition_high'
                    onClick={() =>
                      scrollToPage(x.accommodationtLink, location, navigate)
                    }
                  >
                    SHOW MORE
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default RoomAndApartments;
