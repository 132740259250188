import { scroller } from 'react-scroll';

const scrollTarget = (target: string) =>
  scroller.scrollTo(target, {
    spy: true,
    smooth: true,
    offset: target === 'discover' || target === 'accommodations' ? 64 : 0,
    duration: 500,
  });

const scrollToPage = async (target: string, location: any, navigate: any) => {
  if (target === 'impressum') {
    await navigate(`/${target}`);
    scrollTarget(target);
  } else if (target === 'rules') {
    await navigate(`/${target}`);
    scrollTarget(target);
  } else if (target === 'duplex') {
    await navigate(`/${target}`);
    scrollTarget(target);
  } else if (target === 'apartments') {
    await navigate(`/${target}`);
    scrollTarget(target);
  } else if (target === 'room') {
    await navigate(`/${target}`);
    scrollTarget(target);
  } else if (location.pathname !== '/' && target !== 'footer') {
    await navigate('/');
    scrollTarget(target);
  } else {
    scrollTarget(target);
  }
};

export default scrollToPage;
