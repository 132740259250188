import React from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import discover1 from '../../../../assets/imgs/home/discover/discover_1.jpg';
import discover2 from '../../../../assets/imgs/home/discover/discover_2.jpg';
import discover3 from '../../../../assets/imgs/home/discover/discover_3.jpg';

const Discover = () => {
  const discoverText1 = () => {
    return (
      <>
        <h2>Discover Split</h2>
        <p>
          Welcome to Split, a 1700 years old town in which history reaches far
          into ancient times!
        </p>
        <p>
          There is no particular need to explain its attractiveness and
          worthiness of sightseeing. The Split timeline started counting from
          295 AC when famous Roman Emperor Diocletian started to build his
          residential palace. The monumental palace is a living organism for
          more than 17 centuries.
        </p>
        <p>
          The importance of Diocletian's Palace far transcends the local
          significance because of its level of preservation and because of the
          string of original architectural forms that as well announce the new
          Early Christian, Byzantine and early Medieval art. Over the centuries,
          under the Byzantium, Venetian, Napoleon's and Habsburg rule, Split
          became an imperial palace with medieval, renaissance and baroque
          inlays.
        </p>
      </>
    );
  };

  const discoverText2 = () => {
    return (
      <>
        <h2>Discover Split</h2>
        <p>
          Explore the historical palace, walk the path of Diocletian and immerse
          into the role of the Roman Emperor! Within walking distance of Korta
          Apartments, truly experience the ancient atmosphere while touring the
          city's famous tourist attractions: impressive original ruins such as
          the four fortification gates, the Temple of Jupiter, famous cellars,
          Peristil and St. Dominus, the oldest Catholic cathedral in the world.
        </p>
        <p>
          Furthermore, with more than 300 sunny days per year, crystal clear
          sea, beautiful nearby beaches and park-mountain Marjan, along with
          numerous museums and forms of traditional, cultural or innovative
          events, Split is a true Croatian tourism champion.
        </p>
      </>
    );
  };

  const discoverText3 = () => {
    return (
      <>
        <h2>Discover Split</h2>
        <ul>
          <li>St. Domnius Cathedral</li>
          <li>Peristil</li>
          <li>Temple of Jupiter</li>
          <li>Vestibul</li>
          <li>Golden Gate</li>
          <li>Silver Gate</li>
          <li>Iron Gate</li>
          <li>Brass Gate</li>
          <li>Diocletian's cellars</li>
          <li>Riva</li>
          <li>Prokurative square</li>
          <li>Pjaca square</li>
          <li>Fruit square</li>
          <li>Veli Varoš</li>
        </ul>
      </>
    );
  };

  const discoverTexts: any[] = [discoverText1, discoverText2, discoverText3];
  const discoverImgs: string[] = [discover1, discover2, discover3];

  const sliderText = React.useRef<Slider | null>(null);
  const sliderImg = React.useRef<Slider | null>(null);

  const nextSlide = () => {
    sliderText.current?.slickNext();
    sliderImg.current?.slickNext();
  };
  const prevSlide = () => {
    sliderText.current?.slickPrev();
    sliderImg.current?.slickPrev();
  };

  const slickSettingsOne = {
    adaptiveHeight: true,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickSettingsTwo = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id='discover'>
      <div className='discover container'>
        <div className='discover_container'>
          <div className='discover_container_text fade_in'>
            <Slider ref={sliderText} {...slickSettingsOne}>
              {discoverTexts.map((x: any) => x())}
            </Slider>
            <div className='discover_container_buttons fade_in'>
              <div
                className='opacity_transition_low'
                onClick={() => prevSlide()}
              >
                {Constants.arrowLeft(Constants.complementaryColorDark)}
              </div>
              <div
                className='opacity_transition_low'
                onClick={() => nextSlide()}
              >
                {Constants.arrowRight(Constants.complementaryColorDark)}
              </div>
            </div>
          </div>
          <div className='discover_container_img'>
            <Slider ref={sliderImg} {...slickSettingsTwo}>
              {discoverImgs.map((x: string) => (
                <img
                  src={x}
                  alt={`discover${discoverImgs[discoverImgs.indexOf(x)]}`}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;
