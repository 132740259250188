import kortaLogo from '../../../../assets/imgs/home/korta_logo.svg';
import separator from '../../../../assets/imgs/separator.svg';

const About = () => {
  return (
    <div id='about'>
      <div className='about container'>
        <div className='about_logo fade_in'>
          <img src={kortaLogo} alt='gold korta logo' />
        </div>
        <div className='about_text fade_in'>
          <p>
            Situated within a few minutes' walk from the famous Roman
            Diocletian's palace, the luxurious Korta apartments offer high
            comfort, contemporary elegance and enchanting atmosphere of the city
            center.
          </p>
          <p>
            In the immediate vicinity of local tourist attractions, various
            shops, restaurants and bars, yet hidden in the tranquility of the
            old town and its red rooftops, Korta apartments represent an ideal
            place to stay while in Split.
          </p>
          <p>
            Discover the central city location, elegance of Korta apartments and
            rooms and our dedication in providing warm welcome and professional
            service! Wishing you a nice stay,
          </p>
          <p>Korta Team</p>
        </div>
      </div>
      <div className='about_img'>
        <img src={separator} alt='separator' />
      </div>
    </div>
  );
};

export default About;
