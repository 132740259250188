import { useEffect } from 'react';
import rulesHero from '../../assets/imgs/home/gallery/1.jpg';
import Constants from '../../utils/Constants';
import FadeInSection from '../../utils/FadeInSection';

const Rules = () => {
  FadeInSection();

  useEffect(() => {
    document.title = `${Constants.PROPERTY_NAME} - Rules`;
  }, []);
  
  return (
    <div id='rules' className='rules'>
      <div className='rules_hero'>
        <img src={rulesHero} alt='rules hero' />
      </div>
      <div className='rules_container container fade_in'>
        <h1>Hotel Rules</h1>
        <p>
          KORTA Apartments are operated by the Company MANAS D.O.O. with
          headquarters in Barakovićeva 18, HR - 21 000 Split, Croatia,
          registered in the Commercial Court in City of Split under MBS:
          060086339 and with VAT ID no.: HR 77290534017.
        </p>
        <h3>Apartments Korta</h3>
        <p>
          4-star family-friendly guesthouse in the city center A roundtrip
          airport shuttle, 3 beach bars, and a terrace are just a few of the
          amenities provided at Apartments Korta. In addition to a garden and
          dry cleaning/laundry services, guests can connect to free in-room
          WiFi.
        </p>
        <p>You'll also enjoy perks such as:</p>
        <ul>
          <li>
            Self parking (surcharge), limo/town car service, and an area shuttle
          </li>
          <li>Express check-out, a computer station, and luggage storage</li>
          <li>
            Multilingual staff, tour/ticket assistance, and free newspapers
          </li>
        </ul>
        <h3>Room features</h3>
        <p>
          All 12 individually furnished rooms feature comforts such as pillow
          menus and laptop-compatible safes, in addition to thoughtful touches
          like laptop-friendly workspaces and air conditioning.
        </p>
        <p>Extra conveniences in all rooms include:</p>
        <ul>
          <li>Highchairs and free infant beds</li>
          <li>
            Hypo-allergenic bedding, pillowtop mattresses, and down comforters
          </li>
          <li>Bathrooms with showers and free toiletries</li>
          <li>Flat-screen TVs with satellite channels</li>
          <li>Private yards, wardrobes/closets, and electric kettles</li>
        </ul>
        <h3>Policies</h3>
        <p>Policies</p>
        <h3>Check-in</h3>
        <p>
          Check-in from 2:00 PM - 21:00 PM Early check-in subject to
          availability Minimum check-in age - 16
        </p>
        <h3>Express check-out</h3>
        <p>Special check-in instructions</p>
        <h3>Miscellaneous</h3>
        <p>
          This property offers transfers from the cruise terminal, ferry
          terminal, airport, and train station (surcharges may apply); to
          arrange pick-up, contact the property 48 hours prior to arrival, using
          the contact information on the booking confirmation
        </p>
        <p>There is no front desk at this property</p>
        <p>
          Guests planning to arrive outside of normal check-in hours must
          contact the property in advance for check-in instructions and an
          access code; your host will greet you
        </p>
        <p>
          To make arrangements for check-in please contact the property at least
          72 hours before arrival using the information on the booking
          confirmation
        </p>
        <p>
          If you are planning to arrive after 8:00 PM please contact the
          property in advance using the information on the booking confirmation
        </p>
        <h3>Access methods</h3>
        <p>Access code, host will greet you</p>
        <div className='rules_container_section fade_in'>
          <h2>Important informations</h2>
          <h3>Fees</h3>
          <p>You'll be asked to pay the following charges at the property:</p>
          <ul>
            <li>
              A tax is imposed by the city and collected at the property.
              Exemptions or reductions might apply. For more details, please
              contact the property using the information on the reservation
              confirmation received after booking.
            </li>
            <li>
              We have included all charges provided to us by the property.
            </li>
          </ul>
          <h3>Optional extras*</h3>
          <p>Airport shuttle fee: from EUR 50 per vehicle (one-way)</p>
          <p>Self parking fee: EUR 12 per day (in/out privileges)</p>
          <p>
            Late check-out is available for a fee (subject to availability,
            amount varies)
          </p>
          <p>Housekeeping is available for an additional fee</p>
          <p className='rules_container_section_small_print'>
            *The above list may not be comprehensive. Fees and deposits may not
            include tax and are subject to change.
          </p>
          <h3>You need to know</h3>
          <p>
            This property is managed by a professional host. The provision of
            housing is linked to their trade, business, or profession.
          </p>
          <p>
            Extra-person charges may apply and vary depending on property
            policy.
          </p>
          <p>
            Government-issued photo identification and a cash deposit may be
            required at check-in for incidental charges.
          </p>
          <p>
            Special requests are subject to availability upon check-in and may
            incur additional charges; special requests cannot be guaranteed.
          </p>
          <p>
            Guests must contact this property in advance to reserve cribs/infant
            beds, rollaway/extra beds, sofa beds, and high chair.
          </p>
          <p>Onsite parties or group events are strictly prohibited.</p>
          <p>Long-term renters welcome.</p>
          <p>Noise-free guestrooms cannot be guaranteed.</p>
          <p>
            Safety features at this property include a carbon monoxide detector,
            a fire extinguisher, a smoke detector, a security system, a first
            aid kit, and window guards.
          </p>
          <p>
            Be prepared: check the latest COVID-19 travel requirements and
            measures in place for this destination before you travel.
          </p>
          <p>We should mention:</p>
          <ul>
            <li>This property does not have elevators</li>
            <li>Only registered guests are allowed in the guestrooms</li>
            <li>Alcohol is not served at this property</li>
            <li>
              This property welcomes guests of all sexual orientations and
              gender identities (LGBTQ friendly)
            </li>
          </ul>
        </div>
        <div className='rules_container_section fade_in'>
          <h2>Cancellation / prepayment</h2>
          <p>
            Apartments Korta offers free cancellation on select room rates,
            because flexibility matters! Cancellation and prepayment policies
            vary according to apartment type. Please choose the dates of your
            stay and check the conditions of your required room.
          </p>
          <h3>Fully refundable cancellation policy (100% refund)</h3>
          <p>
            For the units offering free cancellation, paid amount is fully
            refundable if cancellation is made no less than the indicated number
            of days prior to arrival.
          </p>
          <h3>Non-Refundable cancellation policy</h3>
          <p>
            For the units with indicated Non-refundable rate, if you change or
            cancel your booking you will not get a refund or credit to use for a
            future stay. This policy will apply regardless of COVID-19, subject
            to any local consumer laws.
          </p>
        </div>
        <div className='rules_container_section fade_in'>
          <h2>Children and beds</h2>
          <h3>Child policies</h3>
          <p>Children of any age are welcome.</p>
          <p>
            Children aged 4 years and above are considered adults at this
            property.
          </p>
          <p>
            To see correct prices and occupancy information, please add the
            number of children in your group and their ages to your search.
          </p>
          <h3>Cot and extra bed policies</h3>
          <p>0 - 2 years, the cot is free upon request.</p>
          <p>
            The number of cots allowed is dependent on the option you choose.
            Please check your selected option for more information.
          </p>
          <p>There are no extra beds available at this property.</p>
          <p>All cots are subject to availability.</p>
          <h3>No age restriction</h3>
          <p>There is no age requirement for check-in.</p>
          <p>
            Payments by Booking.com Booking.com takes your payment on behalf of
            the property for this reservation. During your stay you can pay for
            any extras using Maestro, Mastercard, Visa, UnionPay credit card and
            Diners Club.
          </p>
        </div>
        <div className='rules_container_section fade_in'>
          <h2>Restrictions</h2>
          <h3>Smoking</h3>
          <p>Smoking is not allowed.</p>
          <h3>Parties</h3>
          <p>Parties/events are not allowed</p>
          <h3>Quiet hours</h3>
          <p>Guests must be quiet between 22:00 and 08:00.</p>
          <p>Pets</p>
        </div>
        <div className='rules_container_section fade_in'>
          <h2>Notice on how to submit complaints</h2>
          <p>
            It is in the interest of the guest to try to solve his complaint
            immediately at the destination. The guest is obliged to cooperate
            with the hotel staff in good faith to eliminate the causes of the
            complaint. If there is no improvement even after a complaint, the
            guest must ask for a confirmation that shows that the service was
            not provided, or that it was not provided in the manner agreed upon.
            The guest must attach the confirmation to the written complaint.
          </p>
          <p>
            The guest must file a written complaint within 8 days after using
            the service. If the guest submits a written complaint after that
            deadline, the Hotel is not obliged to take such complaint into
            account. The hotel will resolve only those complaints that could not
            be resolved on the spot.
          </p>
          <p>
            Pursuant to Art. 10 of the Consumer Protection Act, complaints about
            the purchased product or the service provided must be submitted in
            writing to the address: MANAS d.o.o., Korta Apartments, Plinarska 1,
            21000 Split, Croatia or electronically to the address
            reservations@kortasplit.com with the name and surname, and the
            contact for the delivery of the a The hotel delivers the response to
            the complaint in written form no later than 15 days from the day of
            receipt of the complaint, to the address that the guest left as a
            contact, or to the address from which the complaint was received
          </p>
        </div>
        <div className='rules_container_section fade_in'>
          <h2>Jurisdiction</h2>
          <p>
            All possible disputes or disagreements will be resolved peacefully
            and by agreement in a manner acceptable to both parties.
          </p>
          <p>
            If a solution cannot be reached, they contract the local
            jurisdiction of the Commercial Court in Split.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Rules;
