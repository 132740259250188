import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/home/Home';
import Duplex from './pages/accommodations/duplex/Duplex';
import Apartments from './pages/accommodations/apartments/Apartments';
import Room from './pages/accommodations/room/Room';
import Impressum from './pages/impressum/Impressum';
import Rules from './pages/rules/Rules';
import OnReloadScrollToTop from './utils/OnReloadScrollToTop';
import { useEffect } from 'react';

const App: React.FC = () => {
  let doc: HTMLElement = document.documentElement;

  OnReloadScrollToTop();

  const setHight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  useEffect(() => {
    setHight();
  }, []);

  window.addEventListener('resize', setHight);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='duplex' element={<Duplex />} />
          <Route path='apartments' element={<Apartments />} />
          <Route path='room' element={<Room />} />
          <Route path='impressum' element={<Impressum />} />
          <Route path='rules' element={<Rules />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
